@import '../../assets/styles/colors';
@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.summary {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.summaryRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 13px 7px 7px;
  font-size: $main-font-size;
  font-weight: 700;

  &.summaryRowWithBorder {
    border-top: 1px solid $slate-gray;
  }
}

.summaryRowLabel {
  display: flex;
  justify-content: flex-start;
  color: $slate-gray;
}

.summaryRowValue {
  display: flex;
  justify-content: flex-end;
  color: $secondary-color;
}

.table, .tableShort {
  tbody {
    max-height: calc(100vh - 365px) !important;
  }

  tr {
    height: 32px;

    td,
    th {
      &:nth-child(1) {
        width: 40px;
      }

      &:nth-child(5) {
        width: 50px;
      }
    }
  }
}

.tableShort {
  tr {
    td,
    th {
      &:nth-child(4) {
        width: 50px;
      }
    }
  }
}

.status {
  display: inline-block;
  padding: 3px 8px;
  border-radius: $small-border-radius;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.dateSelectorContainer {
  display: flex;
  align-items: center;
  max-width: 200px;
  margin-left: 16px;
}

.rightControls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.title {
  margin-top: 8px;
  align-content: center;
  color: $black;
  font-weight: 700;
  font-size: 18px;
  line-height: 48px;
}

.modalRoot {
  width: 465px !important;
}

.fieldsContainer {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.textFormField {
  margin-bottom: 20px;
  :global {
    .MuiInput-root {
      max-height: 150px;
      height: 48px;
    }
    .MuiInputBase-input {
      font-size: 14px;
      color: $black;
      font-weight: 400;
    }
  }
}

.autocompleteField {
  margin-bottom: 20px;
  height: 48px !important;
  :global {
    .MuiAutocomplete-inputRoot {
      height: 48px !important;

      .MuiInputBase-input {
        height: 48px !important;

        font-size: 14px;
        color: $black;
        font-weight: 400;
      }

      .MuiAutocomplete-endAdornment {
        right: 5px;
      }

      &:before {
        display: none;
      }
    }
  }
}

.selectFormField {
  margin-bottom: 20px;
  height: 48px !important;
  background: $white;
  :global {
    .MuiSelect-icon {
      top: 12px;
    }

    .MuiSelect-select {
      font-size: 14px;
      color: $black;
    }
  }
}

.checkBox {
  padding: 0 9px;
}

.amountField {
  height: 24px;
}

.noBottomBorder {
  td,
  th {
    border: none !important;
  }
}

.treasuryNotReady {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
  text-align: center;
  gap: 20px;

  
  .dateSelectorContainer {
    position: absolute;
    top: 60px;
    left: 20px;
    margin-right: auto;
  }
}

.treasuryNotReadyTitle {
  font-size: 18px;
  font-weight: 600;
  color: $tundora;
}
