@import '../../assets/styles/colors';
@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.table {
  tr {
    height: 30px;
    background: $white;
    td, th {
      &:nth-child(1) {
        width: 3%;
      }

      &:nth-child(2) {
        width: 22%;
      }
    }
  }

  thead {
    tr {
      th {
        padding: 2px 5px 3px;
        line-height: 28px;

        &:last-of-type {
          padding-right: 0;
        }
      }
    }
  }

  tbody {
    position: relative;

    .parentRow {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }

  tfoot {
    tr {
      td {
        &:nth-child(1) {
          width: 25%;
        }

        &:nth-child(2) {
          width: auto;
        }
      }
    }
  }
}

.selectField {
  width: 110px;
  height: 36px;

  @include select-field-with-top-label;
}

.fullScreenModal {
  overflow-y: auto;
  table {
    tbody {
      // Padding + card header + table header + table footer
      max-height: calc(95vh - 224px) !important;
    }
  }
  .cardContainer {
    height: calc(95vh - 30px);

    :global {
      .MuiCardContent-root {
        height: 100%;
      }

      .MuiTableContainer-root {
        max-height: calc(95vh - 55px);
      }
    }
  }
}
