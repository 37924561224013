@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.dataGrowth {
  padding: 2px 4px;
  border-radius: $small-border-radius;
  min-width: 40px;
  overflow: hidden;
  text-align: center;
  line-height: 16px;
  color: $red-text !important;
  background: $red-background !important;
  font-weight: 700 !important;
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);

  &.dataGrowthPositive {
    color: $green-text !important;
    background: $green-background !important;
  }
}