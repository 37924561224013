@import '../../assets/styles/colors.scss';
@import '../../assets/styles/variables.scss';

.dashboardBlock {
  position: relative;
  padding: 14px 24px;
  border-radius: $main-border-radius;
  min-height: 100%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.gridContainer {
  flex-direction: column;
}

.tabButtonsHeader {
  padding-top: 20px;
  padding-bottom: 20px;
}
.headerButtons {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.gridContainerWrapper {
  border-bottom: 1px solid #ededed;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 0px 14px;
  gap: 26px;
  position: relative;
  flex-wrap: wrap;
}

.gridContainerWrapper::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  border-bottom: 1px solid #ededed;
}

.gridContainerWrapperMetrics {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: flex-start;
  padding: 10px 0px 14px;
  gap: 26px;
  position: relative;
}
.gridContainerWrapperMetrics::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
}
.gridContainerWrapperMetricsLastRow {
  border-top: 1px solid #ededed;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: flex-start;
  padding: 10px 0px 14px;
  gap: 26px;
  position: relative;
}
.gridContainerWrapperMetricsLastRow::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
}

.blockContainerNotInitial {
  display: grid;
  padding-top: 0 !important;
}

.blockContainerInvestments{
  padding-top: 24px !important;
}

.dashboardAvatarWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 75px;
}

.dashboardAvatarImg {
  width: 75px;
  max-height: 75px;
}

.dashboardBlockTitleWrapper {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 8px;

  > .dashboardBlockContent {
    flex: 1;
    margin: 0;
  }

  + .dashboardBlockTitleWrapper {
    margin-top: 10px;
  }
}

.dashboardBlockTitleWrapperWithBorder {
border-top: 1px solid $button-border-color;
}

.dashboardBlockTitleLink {
  > div {
    border: none;
    width: 16px;
    height: 16px;
    padding: 0;
  }
}

.subsidiariesLinks {
  display: flex;
  flex-wrap: wrap;
  gap: 5px 20px;

  .link {
    display: inline-flex;
    padding: 0;
  }
}

.dashboardBlockTitle {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: $secondary-color;
}

.dashboardBlockContent {
  display: flex;
  font-size: $main-font-size;
  line-height: 20px;
  flex-direction: column;
  margin-left: 10px;
  flex-grow: 1;
}

.dashboardBlockContentIcon {
  margin-right: 8px;
  width: 16px;
  height: 16px;
  vertical-align: middle;
}

.dashboardBlockContentIconEdit {
  margin-right: 8px;
  width: 24px;
  height: 24px;
  svg {
    fill: $slate-gray;
    width: 32px;
    height: 32px;
  }
}

.dashboardBlockContentLabel {
  color: $gothic;
  display: flex;
  justify-content: flex-start;
  margin: 0;
}

.dashboardBlockContentValue {
  color: $dark-blue;
  font-weight: 600;
  justify-content: flex-start;
  font-size: 18px;
  padding-top: 7px;
}

.dashboardBlockContentValueDescription {
  font-size: 14px;
}

.dashboardBlockContentValueNumbers {
  color: $dark-blue;
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  font-size: 18px;
  padding-top: 7px;
}
.headerContainer {
  padding-bottom: 20px;
}

.linkIcon {
  margin-left: 7px;
  vertical-align: middle;
  height: 25px;
  width: 25px;
}

.salesforceLinkIcon {
  margin-right: 20px;
  vertical-align: middle;
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.warningIconOutline {
  margin-right: 4px;
  padding-bottom: 2px;
  vertical-align: middle;
  color: $sunshade !important;
  height: 150%;
}

.editIcon {
  cursor: pointer;
  height: 18px;
  font-size: 16px;
  line-height: 18px;
  margin-left: 5px;

  svg {
    height: 18px;
  }
}

.disclaimer {
  font-size: 12px;
  width: 200px;
  margin-left: 80px;
  justify-content: center;
  line-height: 16px;
  color: $gothic;
  margin-top: 10px;
}

.select {
  width: 100px;
  margin-top: 10px;
  justify-content: center;
}

.warningLink {
  color: $sunshade !important;
  border-color: $sunshade !important;
}

.duplicatesContainer {
  vertical-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  margin-left: 0 !important;
}

.duplicateGridItem {
  display: flex;
  justify-content: left;
  align-items: left;
  text-align: left !important;
}

.duplicateGridItemButtons {
  display: flex;
  justify-content: left;
  align-items: center;
  text-align: left !important;
}

.duplicateLink {
  color: $primary-color;
  cursor: pointer;
  font-style: bold;
}

.mergeButton {
  font-size: 12px;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.duplicateModal {
  max-width: 275px;
}

.editIcon {
  margin-top: 0.5px;
  opacity: 0.8;
}

.actionBtn {
  cursor: pointer;
  size: 1rem;
}

.scrollableDiv {
  min-width: 450px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 600px;
  &::-webkit-scrollbar {
    border: none !important;
  }
}

.table {
  max-width: 600px;
  margin-bottom: 15px;
  tbody {
    max-height: 25vh !important;
  }

  th {
    &:nth-child(2) {
      width: 150px;
    }
  }
  td {
    &:nth-child(3) {
      width: 100px;
    }
    &:nth-child(4) {
      width: 50px;
    }
  }
}

.warningIcon {
  // margin-right: 2px;
  fill: $sunshade;
  font-size: 1.5rem;
}

.reported {
  opacity: 50%;
}

.menuIcon {
  position: absolute;
  right: 5px;
  top: 1px;
  cursor: pointer;
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 20px;
  color: $primary-color;
  margin-right: 20px;
  min-width: 90px;
}

.downChevron {
  fill: $primary-color;
  width: 30%;
  height: 30%;
}

.editButton {
  width: 100%;
}

.fieldName {
  text-align: left;
}

.link {
  font-size: 14px;
  font-weight: 400;
  color: $primary-color;
  cursor: pointer;
}
