@import './colors';
@import './variables';

.pricePrefix {
  color: $dusty-gray;
  padding-right: 5px;
}

.colorPrimary {
  color: $primary-color !important;
}

.colorSecondary {
  color: $secondary-color !important;
}

.textCenter {
  text-align: center !important;
}

.textLeft {
  text-align: left !important;
}

.textRight {
  text-align: right !important;
}

.textJustify {
  text-align: justify !important;
}

.tableTextLeft {
  text-align: left !important;
  padding-left: 10px !important;
  > * {
    justify-content: flex-start;
  }
}

.tableTextRight {
  text-align: right !important;
  padding-right: 10px !important;
  > * {
   justify-content: flex-end;
  }
}

.tableTextCenter {
  text-align: center !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.textTransformNone {
  text-transform: none;
}

.textCapitalize {
  text-transform: capitalize;
}

.fullWidth {
  width: 100%;
}

.cursorPointer {
  cursor: pointer;
}

.verticalAlignMiddle {
  vertical-align: middle;
}



// should be in middle of the screen

.multiSelectToolbar {
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 4px;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2000;

  &:empty {
    padding: 0;
  }
}

.multiSelectToolbarModal {
  @extend .multiSelectToolbar;
  bottom: 15%;
}

.multiSelectToolbarWithSidebar {
  left: calc(50% + (#{$drawer-expanded-width} / 2));
  transform: translateX(-50%);
}

.multiSelectToolbarWithNotes {
  left: calc(50% - (#{$notesDrawerWidth} / 2));
  transform: translateX(-50%);
  &.multiSelectToolbarWithSidebar {
    left: calc(50% + (#{$drawer-expanded-width} / 2) - (#{$notesDrawerWidth} / 2));
    transform: translateX(-50%);
  }
}

.mappingProgress {
  position: absolute;
  right: 0;
}

.pageTitle {
  display: flex;
  align-items: center;

  font-size: 20px !important;
  color: $dark-slate-blue;
  margin: 10px 0px 10px 5px;
}

.pageTitleIcon {
  margin-right: 2px;

  path {
    fill: $blue-wale;
  }
}

.passwordInput {
  :global {
    .MuiInput-root {
      > input {
        text-align: left !important;
      }
    }
  }
}

.header {
  font-weight: 500;
  font-size: 14px;
  color: $slate-gray;
  margin-bottom: 15px;
}

.cardTitle {
  font-weight: 800;
  font-size: 18px;
  color: $black;
  justify-content: center;
  align-items: center;
}

.sectionTitle {
  font-weight: 800;
  font-size: 324px;
  color: $black;
}

.nestedRowColumn {
  width: 100% !important;
  padding: 16px 24px !important;
  background: $gray-background-color;
}

.expandIcon {
  svg {
    path {
      fill: $icon-color;
    }
  }
}

.expandIconOpen {
  transform: rotate(180deg);
}

.iconPathFillWhite {
  path {
    fill: $white;
  }
}

.iconPathStrokeWhite {
  path {
    stroke: $white;
  }
}

.dueDiligenceHeader {
  line-height: 1.25 !important;
  color: $secondary-color !important;
}

.dueDiligenceSubmitButton {
  padding: 8px 15px !important;
  height: 40px !important;
}

.statusCard {
  display: inline-block;
  margin-left: 20px;
  padding: 0 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  border-radius: $small-border-radius;
}

.yellowTag {
  @extend .statusCard;
  background: $barley-white;
  color: $sunshade !important;
}

.grayCard {
  @extend .statusCard;
  color: $slate-gray;
  background: $past-gray-background;
}

.activeTag {
  @extend .statusCard;
  color: $green-text;
  background: $green-background;
}

.blueTag {
  @extend .statusCard;
  color: $primary-color;
  background: $blue-background;
}

.redTag {
  @extend .statusCard;
  color: $red-text;
  background: $red-background;
}

.greenTag {
  @extend .statusCard;
  color: $green-text;
  background: $green-background;
}

.inlineButton {
  padding: 0;
  background-color: transparent !important;
  color: $active-blue !important;
  text-align: left !important;
  font-weight: 600 !important;
  font-size: 12px !important;

  &:hover,
  &:focus {
    color: $navy-blue !important;
    background-color: transparent !important;
  }

  span {
    display: block !important;
  }
}

.itemsCount {
  margin-top: 10px;
  padding: 6px;
  font-size: $main-font-size;
  line-height: 20px;
  text-align: left;
  color: $dark-blue;
}

.warningMessage {
  margin-bottom: 10px;
  padding: 5px 13px;
  background: $barley-white;
  border: 1px solid $candlelight;
  border-radius: 4px;
  white-space: pre-wrap;
}

.reportingStatus {
  display: inline-block;
  margin-top: 12px;
  font-size: $main-font-size;
  line-height: 14px;
  padding: 3px 7px;
  border-radius: $small-border-radius;
  color: $white;
  cursor: default;
  text-align: center;
}

.notStarted {
  background: $border-color;
  color: $slate-gray !important;

  :hover {
    background: $border-color;
    color: $slate-gray;
  }
}

.inProgress {
  background: $yellow-background;
  color: $yellow-text !important;

  :hover {
    background: $yellow-background;
    color: $yellow-text;
  }
}

.overdue {
  background: $red-background;
  color: $red-text !important;

  :hover {
    background: $red-background;
    color: $red-text;
  }
}

.complete {
  background: $green-background;
  color: $green-text !important;

  :hover {
    background: $green-background;
    color: $green-text;
  }
}

.blue {
  background: $blue-background;
  color: $primary-color !important;

  :hover {
    background: $blue-background;
    color: $primary-color;
  }
}

.overflowXAuto {
  overflow-x: auto;
}

.overflowYAuto {
  overflow-x: auto;
}

.textRegular {
  font-weight: 400 !important;
}

.tabSpacing {
  padding-left: 2rem !important;
}


.inactiveFormField {
  cursor: pointer;
  color: $transparent-gray;
  width: 100%;
  display: flex;
  align-items: center;
}



.displayNone {
  display: none;
}


.noMargin {
  margin: 0 !important;
}

.bold {
  font-weight: 600;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed ;
  pointer-events: none;

}

.cellWithGrowthChip {
  padding-right: 65px !important;
  position: relative;
}