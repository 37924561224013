@import '../../assets/styles/colors.scss';
@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins.scss';

.gridContainer {
  padding-top: 10px !important;
}

.table {
  table {
    tbody {
      max-height: calc(100vh - 430px);
    }

    tr {

      th,
      td {
        padding-left: 20px !important;

        &:nth-child(1) {
          width: 55%;
        }

        &:nth-child(2) {
          width: 10%;
        }

        &:nth-child(3) {
          width: 20%;
        }

        &:nth-child(4) {
          width: 15%;
        }
      }
    }
  }
}

.headingRow {
  height: 36px;
  color: $secondary-color;
  font-size: 14px;
  font-weight: 700;

  svg {
    path {
      fill: $primary-color;
    }
  }

  button {
    margin-right: 10px;
  }
}

.nameWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: nowrap;
}

.name {
  flex: 1;
  white-space: break-spaces;
}

.count {
  min-width: 20px;
  height: 20px;
  background: $success-green;
  border-radius: 50%;
  text-align: center;
  font-size: $main-font-size;
  line-height: 20px;
  color: $white;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.status {
  &.statusNotStarted {
    :global {
      .MuiInput-input {
        background: $border-color;
        color: $slate-gray !important;
      }
    }
  }

  &.statusInReview {
    :global {
      .MuiInput-input {
        background: $yellow-background;
        color: $yellow-text;
      }
    }
  }

  &.statusActionRequired {
    :global {
      .MuiInput-input {
        color: $red-text;
        background: $red-background;
      }
    }
  }

  &.statusCompleted {
    :global {
      .MuiInput-input {
        background: $green-background;
        color: $green-text !important;
      }
    }
  }

  :global {
    .MuiInput-input {
      display: flex;
      padding: 3px 32px 3px 8px !important;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      align-items: center;
      gap: 8px;
    }

    .MuiSelect-icon {
      top: 0px;
      right: 4px;
    }
  }
}

.completeIcon {
  svg {
    width: 24px;
    height: 24px;
  }
}

.completeIconCompleted {
  path {
    fill: $success-green;
  }
}

.commentIconHasComments {
  svg {
    circle {
      stroke: $persimmon;
      fill: $persimmon;
    }
  }
}

.addButton {
  border: none;

  &:hover {
    border: none;
    text-decoration: underline;

    .addButtonIcon {
      path {
        stroke: $cerulean;
      }
    }
  }
}

.addButtonIcon {
  path {
    stroke: $primary-color;
  }
}

.tableCenter {
  text-align: center !important;
  padding: 8px !important;
  vertical-align: middle !important;
  width: 120px !important;  
}

.actionIconsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin: 0 auto;
}

.actionIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  
  svg {
    path {
      stroke: $primary-color !important;
    }
  }
  
  &:hover {
    color: darken($primary-color, 15%);
    
    svg {
      path {
        stroke: darken($primary-color, 15%) !important;
      }
    }
  }
}

.iconBadge {
  position: absolute;
  top: -7px;
  right: -8px;
  min-width: 16px;
  height: 16px;
  background-color: $santas-gray;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 10px;
  font-weight: 400;
  padding: 0 2px;
  box-sizing: border-box;
}

.formContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px 12px;
  width: 100%;
  min-width: 400px;
  max-width: 600px;

  > div {
    flex: 0 100% !important;
    width: 100%;
    overflow: hidden;

    > label {
      text-align: left;
      color: $secondary-color;
      letter-spacing: 0.2px;
      font-weight: bolder;
    }

    .autocompleteField {
      :global {
        .MuiAutocomplete-inputRoot {
          min-height: 48px;
          height: 48px;
          border: 1px solid $input-border-color;
          border-radius: 5px;
          padding: 8px 36px 8px 8px !important;

          .MuiInputBase-input {
            font-size: 14px;
          }

          .MuiAutocomplete-endAdornment {
            right: 5px;
          }

          &:before {
            display: none;
          }
        }
      }
    }

    .inputField {
      :global {
        .MuiInputBase-input {
          font-weight: normal;
          text-align: left;
        }
      }
    }

    .selectField {
      :global {
        .MuiSelect-select {
          line-height: 30px;
          border-radius: $small-border-radius;
          font-size: 14px;
          outline: none !important;
          border: 1px solid $input-border-color;
        }

        .MuiSelect-icon {
          top: 12px;
        }
      }
    }
  }

  .button {
    width: 100%;
  }
}